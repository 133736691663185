
import Vue from "vue";

export default Vue.extend({
  name: "UpdateMilestoneModal",
  props: {
    activeStatus: {
      type: String
    },
    milestoneId: Number,
    milestoneStatuses: {
      type: Object
    },
    isConcluded: Boolean
  },
  data() {
    return {
      status: this.activeStatus,
      btnLoading: false,
      errors: {} as any
    };
  },
  watch: {
    activeStatus(newStatus) {
      this.status = newStatus;
    }
  },
  methods: {
    update() {
      this.$store
        .dispatch("milestone/UPDATE_MILESTONE_STATUS", {
          milestoneId: this.milestoneId,
          data: {
            status: this.status
          }
        })
        .then(() => {
          this.btnLoading = false;
          (this as any).$alertify.success(
            `Milestone status updated successfully`
          );
          this.$bvModal.hide("update-milestone");
          this.$emit("refresh");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
});
